<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <div class="input-container width600">
        <input
          @keyup.enter.stop="forward"
          v-model="name"
          required
          type="text"
          class="input-standard-white validation fadeIn-2"
          id="name"
          :placeholder="nameLabel"
        />
        <label for="name">{{ nameLabel }}</label>
      </div>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'

export default {
  name: 'ProfilePartnerName',
  components: {
    NavQuestion,
    NavQuestionButtons
  },
  mounted() {
    this.$emit('progress', '76%')
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    isValidated() {
      if (this.name) return true
      return false
    },
    number() {
      if (
        this.$store.getters.checkoutBasket &&
        this.$store.getters.checkoutBasket.coupon &&
        this.$store.getters.checkoutBasket.coupon.charity
      )
        return '11'
      return '9'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    partner() {
      return this.$store.getters.partner
    },
    heading() {
      if (this.personalDetails.marital_status === 'Married')
        return "What is your spouse's full name?"
      if (this.personalDetails.marital_status === 'Separated')
        return "What is you ex partner's full name?"
      return "What is your partner's full name?"
    },
    subHeading() {
      if (this.personalDetails.marital_status === 'Separated') {
        return (
          'Because you are separated it is important to specifically exclude your ex partner as a beneficiary ' +
          'in your will.  If you still want your ex partner to be a beneficiary you can add them as normal.'
        )
      } else {
        return (
          'Please enter your partners full name; including any middle names. ' +
          'Even if you do not wish to include your partner in your Will it is important to provide ' +
          'their details.'
        )
      }
    },
    nameLabel() {
      if (this.personalDetails.marital_status === 'Separated') {
        return "Your ex partner's name"
      } else if (this.personalDetails.marital_status === 'Married') {
        return "Your spouse's name"
      } else {
        return "Your partner's name"
      }
    },
    start() {
      return this.$router.history.current.name === 'WriteMyWillPartnerName'
    },
    forwardTo() {
      if (this.start) return '/write_my_will/partner_gender'
      return '/profile/partner_gender'
    },
    backTo() {
      if (this.start) return '/write_my_will/marital_status'
      return '/profile/marital_status'
    }
  },
  watch: {
    partner: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && value.full_name) this.name = value.full_name
        else if (this.personalDetails && this.personalDetails.partner_full_name)
          this.name = this.personalDetails.partner_full_name
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.save()
    next()
  },
  data() {
    return {
      name: null
    }
  },
  methods: {
    save() {
      this.$store.commit('personalDetails', {
        partner_full_name: this.name
      })
    },
    forward() {
      if (this.isValidated)
        this.$router.push(this.forwardTo).catch((error) => {
          console.log(error)
        })
    }
  }
}
</script>

<style></style>
